import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Pagination from "../components/Pagination";
import Title from "../components/Title";
import ContactListItem from "../components/ListItems/ContactListItem";
import Breadcrumb from "../components/Breadcrumb";

const TypesContactList = ({ pageContext, data, location }) => {
  const {
    currentPage,
    numPages,
  } = pageContext

  return (
    <Layout>
      <Metas title={pageContext.name} />
      <section className="section page-content">
        <div className="container mx-auto px-4">
          <Breadcrumb/>

          <Title title="Annuaire" className="text-primary"/>

          {/*{pageContext.tags && (*/}
          {/*  <div className="articles-themes has-text-centered">*/}
          {/*    <h2 className="title is-6 is-uppercase">Filtrer par type</h2>*/}
          {/*    <div className="tags is-centered">*/}
          {/*      {pageContext.tags.map((tag, index) => (*/}
          {/*        <span key={index} className={`tag  theme theme-${cssCleanClass(tag.name)}${parseInt(pageContext.tid) === parseInt(tag.id) ? ` active` : ``}`}>*/}
          {/*          <Link to={`/contacts${tag.path}`}>{tag.name}</Link>*/}
          {/*        </span>*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}

          <div className="columns is-multiline">
            {data.contacts.nodes.map((node, index) => (
                <div key={index} className="column is-6">
                    <ContactListItem content={node} />
                </div>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </div>
      </section>
    </Layout>
  )
}

export default TypesContactList

export const query = graphql`
    query contactTypesListQuery($tid: Int!, $skip: Int!, $limit: Int!) {
        contacts: allNodeContact(
            filter: {
                status: {eq: true},
                relationships: {
                    field_type: {
                        drupal_internal__tid: {eq: $tid}
                    }
                }
            },
            sort: { fields: created, order: DESC },
            limit: $limit,
            skip: $skip
        ) {
            nodes {
                id
                title
                phone: field_telephone
                email: field_email
                address: field_adresse {
                   value
                }
                contactName: field_contact
                relationships {
                    type: field_type {
                        name
                        path {
                            alias
                        }
                    }
                    image: field_image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 336, maxHeight: 404, cropFocus: CENTER, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }`
